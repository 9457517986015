import React, { useState, useEffect, useContext, useMemo } from "react";
import './MyProcedures.css';
import '../../App.css';
import { Button } from 'react-bootstrap';
import moment from "moment/moment";
import { apiRequest } from "../../Utils/request";
import { Link } from "react-router-dom";
import { ReactComponent as Add } from '../../Assets/Svgs/Plus.svg';
import { ReactComponent as Chart } from '../../Assets/Svgs/ChartLine.svg';
import { ReactComponent as Export } from '../../Assets/Svgs/FileExcel.svg';
import { ReactComponent as Edit } from '../../Assets/Svgs/PencilAlt.svg';
import { PageLoader } from "../../Components/Templates/PageLoader/PageLoader";
import Table from '../../Components/Table/Table'
import { useAuth0 } from "@auth0/auth0-react";
import AppContextStore from "../../context/AppContextStore";
import { CSVLink } from "react-csv";

export const MyProcedures = () => {
    const {user} = useAuth0();
    const {myProc, procCodes, setMyProcContext} = useContext(AppContextStore);
    const [isCodeMapped, setCodeMapped] = useState(false);
    const [myProcLoc, setMyProcLoc] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const mapProcCodes = (myPrcData) => {
        myPrcData.map((proc, ind) => {
            procCodes.map(code => {
                if (code.aga_procedurecodeid == proc._aga_procedurecode_value)
                    myPrcData[ind]._aga_procedurecode_desc = code.aga_description
            })
        })
        setMyProcContext(myPrcData);
        setCodeMapped(true);
    }
    const columns = useMemo(() => [{
        id: 'code',
        Header: 'Procedure Code',
        accessor: '_aga_procedurecode_desc',
        sortable: true
    }, {
        id: 'institution',
        Header: 'Institution',
        accessor: 'aga_institutionhospital',
        sortable: true
    }, {
        id: 'date',
        Header: 'Date',
        accessor: data => data['aga_proceduredate@OData.Community.Display.V1.FormattedValue'],
        sortable: true,
        sortType: (rowA, rowB) => {
            var d1 = new Date(rowA.values.date).getTime();
            var d2 = new Date(rowB.values.date).getTime();
            if (d1 > d2) return 1;
            else if (d1 < d2) return -1;
            else return 0;
        }
    }, {
        id: 'action',
        Header: 'Edit',
        accessor: 'edit',
        sortable: false,
        Cell: ({ row }) => {
            return (
                <Link to={`/edit-procedure/${row.original.aga_procedurelogid}`}>
                    <Button variant="outline-primary" className="btn-sm">
                        <Edit fill="black" className="actionIcon" />
                    </Button>
                </Link>
            )
        }
    }], []);
    const sortBy = React.useMemo(
        () => [{ id: 'date', desc: true }], []
    );
    useEffect(() => {
        setLoading(true);
        apiRequest('GET_PROC', 'POST', { "key": process.env.REACT_APP_API_KEY, "contactid": user.sub.split('|').pop() })
            .then(resp => {
                setLoading(false);
                if(resp.status == 'success') setMyProcLoc(resp.data)
                else console.log('API Failed');
            }).catch(err => {
                setLoading(false);
                console.log('err: ', err);
            });
    }, []);
    useEffect(() => {
        if (!isCodeMapped && procCodes.length > 0 && myProcLoc.length > 0) mapProcCodes(myProcLoc)
    }, [myProcLoc]);
    return (
        <div style={{marginTop: '-0.75rem'}}>
            {isLoading && <PageLoader />}
            <span>*Please click on the blue column headers to sort the columns.</span>
            <div className="sectionHeader font-weight-bold">
                My Procedure Log
                <div>
                    {myProc.length > 0 && 
                        <CSVLink
                            data={myProc}
                            style={{ fontSize: '1rem' }}
                            className="btn btn-light btn-sm mr-2"
                            filename={user.name + "_procedure_log_" + moment().format('MM-DD-YYYY') + ".csv"}
                            headers={[
                                {key: "aga_name", label: "aga_name"},
                                {key: "aga_proceduredate@OData.Community.Display.V1.FormattedValue", label: "aga_proceduredate"},
                                {key: "_aga_procedurecode_desc", label: "_aga_procedurecode_desc"},
                                {key: "aga_institutionhospital", label: "aga_institutionhospital"},
                                {key: "aga_wasthesedationconscious", label: "aga_wasthesedationconscious"},
                                {key: "aga_cecumreached", label: "aga_cecumreached"},
                                {key: "aga_wasthereforeignretrieval", label: "aga_wasthereforeignretrieval"},
                                {key: "aga_description", label: "aga_description"},
                                {key: "aga_indications", label: "aga_indications"},
                                {key: "aga_pathology", label: "aga_pathology"},
                                {key: "aga_complications", label: "aga_complications"},
                                {key: "aga_notes", label: "aga_notes"},
                                {key: "aga_biopsy", label: "aga_biopsy"},
                                {key: "aga_bravoplacementadded", label: "aga_bravoplacementadded"},
                                {key: "aga_dilation", label: "aga_dilation"},
                                {key: "aga_emr", label: "aga_emr"},
                                {key: "aga_fineneedleaspiration", label: "aga_fineneedleaspiration"},
                                {key: "aga_injection", label: "aga_injection"},
                                {key: "aga_mucosalablation", label: "aga_mucosalablation"},
                                {key: "aga_nonvaricealhemostasis", label: "aga_nonvaricealhemostasis"},
                                {key: "aga_polypectomy", label: "aga_polypectomy"},
                                {key: "aga_sphincterotomy", label: "aga_sphincterotomy"},
                                {key: "aga_stenting", label: "aga_stenting"},
                                {key: "aga_stentingbiliary", label: "aga_stentingbiliary"},
                                {key: "aga_stentingpancreatic", label: "aga_stentingpancreatic"},
                                {key: "aga_varicealhemostasis", label: "aga_varicealhemostasis"},
                                {key: "aga_withdrawaltimegreaterthan6minutes", label: "aga_withdrawaltimegreaterthan6minutes"},
                            ]}
                        >
                            <Export fill="black" className="actionIcon" /> Export
                        </CSVLink>
                    }
                    <Link to="/procedure-summary">
                        <Button variant="light" className="btn-sm mr-2" style={{ fontSize: '1rem' }}>
                            <Chart fill="black" className="actionIcon" /> Summary
                        </Button>
                    </Link>
                    <Link to="/add-procedure">
                        <Button variant="light" className="btn-sm" style={{fontSize: '1rem'}}>
                            <Add fill="black" className ="actionIcon" /> Add
                        </Button>
                    </Link>
                </div>
            </div>
            <Table columns={columns} data={myProc} initialState={{sortBy: sortBy}} />
        </div>
    );
}