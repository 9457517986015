import Container from 'react-bootstrap/Container';

export const Footer = () => {
    return (
        <footer>
            <Container className="footerAddr">
                <div className="siteAddr">
                    <p>American Gastroenterological Association</p>
                    <p>4930 Del Ray Avenue, Bethesda, MD 20814</p>
                    <p>301-654-2055</p>
                </div>
            </Container>
            <div className="footerBar">
                <Container className="footerBarActions">
                    <a href="https://www.gastro.org/privacy-policy">Privacy Policy</a>
                    <p>© 2023 American Gastroenterological Association</p>
                </Container>
            </div>
        </footer>
    );
}