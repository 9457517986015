import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import AppContextProvider from './context/AppContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
const AppWithAuth = () => {
  const navigate = useNavigate();
  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || '/', {replace: true});
  };
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <App />
      <ToastContainer />
    </Auth0Provider>
  )
}
const RouteWrap = () => {
  return (
    <Router>
      <AppWithAuth />
    </Router>
  )
}
root.render(
  <AppContextProvider>
    <RouteWrap />
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
