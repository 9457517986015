import React from 'react'
import { useTable, useSortBy } from 'react-table';
import { Table as BootTable } from 'react-bootstrap';

function Table(props) {
    const { columns, data, initialState } = props;
    const {
        getTableProps, headerGroups, rows,
        getTableBodyProps, prepareRow,
    } = useTable({ columns, data, initialState }, useSortBy)

    return (
            <BootTable striped hover {...getTableProps()}>
                <thead style={{ color: '#0c5e8d' }}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                column.sortable ? (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ) : (
                                    <th {...column.getHeaderProps()} >
                                        {column.render('Header')}
                                    </th>
                                )
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </BootTable>
    )
}

export default Table;