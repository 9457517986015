import { React, useContext, useEffect, useState } from 'react';
import { apiRequest } from '../../Utils/request';
import { PageLoader } from '../../Components/Templates/PageLoader/PageLoader';
import './AddProcedure.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../Assets/Svgs/BackArrow.svg';
import AppContextStore from '../../context/AppContextStore';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from "moment/moment";

export const AddProcedure = ({ pageTitle }) => {
    const { myProc, procCodes, procTechs } = useContext(AppContextStore);
    const {user} = useAuth0();
    const [isLoading, setLoading] = useState(false);
    const [pageMode, setPageMode] = useState('');
    const [pageData, setPagedata] = useState({});
    const [swQultyMtrs, setSwQultyMtrs] = useState(false);
    const [skipTechs, setSkipTechs] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();
    const techs = procTechs.map(procTech => { return procTech.techVal })
    const { register, handleSubmit, reset, setValue, watch, getValues, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            "aga_procedurelogid": "",
            "_aga_contactid_value": user.sub.split('|').pop(),
            "aga_name": user.name,
            "aga_cecumreached": "",
            "aga_wasthesedationconscious": "",
            "aga_wasthereforeignretrieval": "false",
            "_aga_procedurecode_value": "",
            "aga_proceduredate": new Date().toISOString().substring(0, 10),
            "aga_institutionhospital": "",
            "aga_description": "",
            "aga_indications": "",
            "aga_pathology": "",
            "aga_notes": "",
            "aga_complications": "",
            "aga_techs": [],
        }
    });
    const watchProcCode = watch("_aga_procedurecode_value", "");
    useEffect(() => {
        let filtred = procCodes.filter(procCode => procCode.aga_procedurecodeid == watchProcCode);
        let codeName = filtred.length > 0 ? filtred[0].aga_description : '';
        let isShowQwlty = (codeName == "Screening Colonoscopy" || codeName == "Colonoscopy") ? true : false;
        if (isShowQwlty && skipTechs.length == 0) setSkipTechs(["aga_cecumreached", "aga_withdrawaltimegreaterthan6minutes"]);
        else if (!isShowQwlty && skipTechs.length > 0) setSkipTechs([]);
    }, [watchProcCode])

    useEffect(() => {
        let showQwlty = false;
        if (skipTechs.length > 0) {
            showQwlty = true;
            let oldVal = getValues('aga_techs');
            skipTechs.map(skpTch => setValue(skpTch, (oldVal.includes(skpTch) ? 'true' : (pageData[skpTch] === false ? 'false': ''))))
            oldVal.filter(skpTch => !skipTechs.includes(skpTch))
            setValue("aga_techs", oldVal)
        } else {
            let oldVal = getValues('aga_techs');
            if(getValues('aga_cecumreached') == 'true') oldVal.push('aga_cecumreached')
            if(getValues('aga_withdrawaltimegreaterthan6minutes') == true) oldVal.push('aga_withdrawaltimegreaterthan6minutes')
            setValue("aga_techs", oldVal);
        }
        if(showQwlty != swQultyMtrs) setSwQultyMtrs(showQwlty)
    }, [skipTechs])

    const convertTechs = (data, forward = false) => {
        if(!forward) {
            let resp = [];
            Object.keys(data).map(key => {
                if (techs.includes(key) && data[key] == true) resp.push(key);
            })
            return resp;
        } else {
            let resp = {}
            techs.map(tech => { if(!skipTechs.includes(tech)) resp[tech] = data.includes(tech); })
            return resp;
        }
    }
    const onSubmit = (data) => {
        data = Object.assign(data, convertTechs(data['aga_techs'], true))
        delete(data['aga_techs']);
        if(!data.aga_procedurelogid) delete(data.aga_procedurelogid);
        data.aga_proceduredate = moment(data.aga_proceduredate).format('DD-MM-YYYY');
        data.key = process.env.REACT_APP_API_KEY;
        Object.keys(data).map(key => {
            if (data[key] === "true" || data[key] === "false") data[key] = JSON.parse(data[key])
        })
        setLoading(true);
        apiRequest('CNG_PROC', 'PUT', data).then(resp => {
            setLoading(false);
            if (resp.status == 'success') {
                toast.success('Log Saved!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    progress: undefined,
                    theme: "light",
                });
                reset()
                if(pageMode == 'edit') setTimeout(() => navigate('/my-procedures'), 3000)
            } else console.log('resp:', resp);
        }).catch(err => {
            setLoading(false);
            toast.error('API Failed!\n + err.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progress: undefined,
                theme: "light",
            })
            console.log('err: ', err);
        })
    }
    useEffect(() => {
        if(pageTitle == 'Edit Procedure' && id) {
            setPageMode('edit')
            let data = myProc.filter(function(proc) { return proc.aga_procedurelogid == id; })[0];
            setPagedata(data);
            data.aga_proceduredate = moment(data.aga_proceduredate).format('YYYY-MM-DD')
            Object.keys(data).map(key => {
                if (!key.includes("@") && !techs.includes(key)) {
                    if (key == "aga_wasthesedationconscious") setValue(key, data[key].toString())
                    else if (key == "aga_cecumreached") setValue(key, data[key].toString())
                    else if (key == "aga_wasthereforeignretrieval") setValue(key, data[key].toString())
                    else if (skipTechs.includes(key)) setValue(key, data[key].toString())
                    else setValue(key, data[key])
                }
            })
            setValue("aga_techs", convertTechs(data))
        } else setPageMode('add')
    }, [])
    return (
        <div>
            {isLoading && <PageLoader />}
            <h4 className="mb-4 text-capitalize" style={{ color: "#1f395b" }}>
                <BackArrow className="actionIcon big mr-2" role="button" title="Go Back" onClick={() => window.history.back()} />
                {pageMode} procedure log
            </h4>
            <Form className="addProcedureForm" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col lg={6} className="loginHint">
                        <input type="hidden" {...register("aga_procedurelogid")} />
                        <input type="hidden" {...register("_aga_contactid_value")} />
                        <input type="hidden" {...register("aga_name")} />
                        <Form.Group>
                            <Form.Label htmlFor="proc_date">Date: </Form.Label>
                            <Form.Control {...register("aga_proceduredate", { required: "date is Required." })}
                                type="date"
                                id="proc_date"
                            />
                            <span className='error'>{errors.aga_proceduredate?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="proc_code">Procedure Code: </Form.Label>
                            <Form.Control {...register("_aga_procedurecode_value", { required: "Required" })}
                                as="select"
                                id="proc_code"
                            >
                                <option value="">Select Procedure Code</option>
                                {procCodes.map(procCode => (
                                    <option key={procCode.aga_procedurecode} value={procCode.aga_procedurecodeid}>{procCode.aga_description}</option>
                                ))}
                            </Form.Control>
                            <span className='error'>{errors._aga_procedurecode_value?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="aga_institutionhospital">Institution/Hospital: </Form.Label>
                            <Form.Control {...register("aga_institutionhospital")}
                                type="text"
                                id="aga_institutionhospital"
                                placeholder="Institution/Hospital Name"
                            />
                            <span className='error'>{errors.aga_institutionhospital?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="proc_techs">Techniques Used: </Form.Label>
                            <Form.Control {...register("aga_techs")}
                                as="select"
                                multiple
                                id="aga_techs"
                                placeholder="Select Techniques used"
                            >
                                {procTechs.map(procTech => {
                                    if (!skipTechs.includes(procTech.techVal))
                                        return (<option key={procTech.key} value={procTech.techVal}>{procTech.techLbl}</option>)
                                })}
                            </Form.Control>
                            <span className='error'>{errors.tech_list?.message}</span>
                        </Form.Group>
                        <Form.Label htmlFor="font-bold" style={{alignSelf: "flex-start"}}>Quality Metrics: </Form.Label>
                        <Row className="w-100">
                            {swQultyMtrs && skipTechs.map((skpTch, ind) => (
                                <Col md={6} className="loginHint">
                                    <Form.Group key={ind}>
                                        <Form.Label>{procTechs.filter(procTech => procTech.techVal == skpTch)[0].techLbl}?</Form.Label>
                                        <div className="d-flex ">
                                            <Form.Check className='ml-3 mr-4'
                                                id={`aga_${skpTch}_yes`}
                                                type="radio"
                                                value="true"
                                                label="Yes"
                                                {...register(skpTch, { required: "Required" })}
                                            />
                                            <Form.Check
                                                id={`aga_${skpTch}_no`}
                                                type="radio"
                                                value="false"
                                                label="No"
                                                {...register(skpTch)}
                                            />
                                        </div>
                                        <span className='error'>{errors[skpTch]?.message}</span>
                                    </Form.Group>
                                </Col>
                            ))}
                            <Col md={6} className="loginHint">
                                <Form.Group>
                                    <Form.Label>Was the sedation consious? </Form.Label>
                                    <div className="d-flex ">
                                        <Form.Check className='ml-3 mr-4'
                                            id="aga_wasthesedationconscious_yes"
                                            type="radio"
                                            value="true"
                                            label="Yes"
                                            {...register("aga_wasthesedationconscious", { required: "Required." })}
                                        />
                                        <Form.Check
                                            id="aga_wasthesedationconscious_no"
                                            type="radio"
                                            value="false"
                                            label="No"
                                            {...register("aga_wasthesedationconscious")}
                                        />
                                    </div>
                                    <span className='error'>{errors.aga_wasthesedationconscious?.message}</span>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="loginHint">
                                <Form.Group>
                                    <Form.Label>Was there foreign retrieval? </Form.Label>
                                    <div className="d-flex ">
                                        <Form.Check className='ml-3 mr-4'
                                            id="aga_wasthereforeignretrieval_yes"
                                            type="radio"
                                            value="true"
                                            label="Yes"
                                            {...register("aga_wasthereforeignretrieval", {})}
                                        />
                                        <Form.Check
                                            id="aga_wasthereforeignretrieval_no"
                                            type="radio"
                                            value="false"
                                            label="No"
                                            {...register("aga_wasthereforeignretrieval")}
                                        />
                                    </div>
                                    <span className='error'>{errors.aga_wasthereforeignretrieval?.message}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label htmlFor="proc_desc">Description: </Form.Label>
                            <Form.Control {...register("aga_description")}
                                as="textarea"
                                id="proc_desc"
                                rows={4}
                            />
                            <span className='error'>{errors.aga_description?.message}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="loginForm" style={{justifyContent: 'flex-start'}}>
                        <Form.Group>
                            <Form.Label htmlFor="proc_indi">Indications: </Form.Label>
                            <Form.Control {...register("aga_indications")}
                                as="textarea"
                                id="proc_indi"
                                rows={4}
                            />
                            <span className='error'>{errors.aga_indications?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="proc_path">Pathology: </Form.Label>
                            <Form.Control {...register("aga_pathology")}
                                as="textarea"
                                id="proc_path"
                                rows={4}
                            />
                            <span className='error'>{errors.aga_pathology?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="proc_comp">Complications: </Form.Label>
                            <Form.Control {...register("aga_complications")}
                                as="textarea" id="proc_comp" rows={4}
                            />
                            <span className='error'>{errors.aga_complications?.message}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="proc_note">Notes: </Form.Label>
                            <Form.Control {...register("aga_notes")}
                                as="textarea"
                                id="proc_note"
                                rows={4}
                            />
                            <span className='error'>{errors.aga_notes?.message}</span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mx-0 justify-content-end">
                    <Button type="reset" variant="secondary" className="mr-2">Clear</Button>
                    <Button type="submit" variant="primary">Save</Button>
                </Row>
            </Form>
        </div>
    );
}