import '../../../App.css';
import React from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import { PageLoader } from '../PageLoader/PageLoader';
import Container from 'react-bootstrap/Container';
import { useAuth0 } from "@auth0/auth0-react";

export const Layout = () => {
  const { isLoading } = useAuth0();
  return (<>
    {isLoading && <PageLoader />}
    <Header />
    <div className="pageBody">
      <Container className='d-flex'>
        <div className="pagePanel">
          <Outlet />
        </div>
      </Container>
    </div>
    <Footer />
  </>);
}