import './PageLoader.css';
import React from 'react';
import { Spinner } from 'react-bootstrap';

export const PageLoader = () => {
    return (
        <div className="pageLoader">
            <Spinner animation="border" className='mb-3' variant='primary' />
            Loading...
        </div>
    )
}