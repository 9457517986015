import React, { useEffect, useState } from 'react'
import { apiRequest } from '../Utils/request';
import AppContextStore from './AppContextStore'

const AppContextProvider = (props) => {
    const [procCodes, setProcCodes] = useState([]);
    const [myProc, setMyProc] = useState([]);
    const [procTechs, setProcTechs] = useState([
        { "key": 1, "techVal": "aga_bravoplacementadded", "techLbl": "Bravo placement added" },
        { "key": 2, "techVal": "aga_biopsy", "techLbl": "Biopsy" },
        { "key": 3, "techVal": "aga_cecumreached", "techLbl": "Cecum reached" },
        { "key": 4, "techVal": "aga_dilation", "techLbl": "Dilation" },
        { "key": 5, "techVal": "aga_emr", "techLbl": "EMR" },
        { "key": 6, "techVal": "aga_fineneedleaspiration", "techLbl": "Fine-needle Aspiration" },
        { "key": 7, "techVal": "aga_injection", "techLbl": "Injection" },
        { "key": 8, "techVal": "aga_mucosalablation", "techLbl": "Mucosal ablation" },
        { "key": 9, "techVal": "aga_nonvaricealhemostasis", "techLbl": "Non-Variseal Hemostatis" },
        { "key": 10, "techVal": "aga_polypectomy", "techLbl": "Polypectomy" },
        { "key": 11, "techVal": "aga_sphincterotomy", "techLbl": "Sphincterotomy" },
        { "key": 12, "techVal": "aga_stenting", "techLbl": "Stenting" },
        { "key": 13, "techVal": "aga_stentingpancreatic", "techLbl": "Stenting Pancreatic" },
        { "key": 14, "techVal": "aga_stentingbiliary", "techLbl": "Stenting Biliary" },
        { "key": 15, "techVal": "aga_varicealhemostasis", "techLbl": "Variseal Hemostatis" },
        { "key": 16, "techVal": "aga_withdrawaltimegreaterthan6minutes", "techLbl": "Withdrawal time > 6 minutes" }
    ]);
    const [procTechsFull, setProcTechsFull] = useState([...procTechs, 
        { "key": 17, "techVal": "aga_wasthesedationconscious", "techLbl": "Sedation Conscious" },
        { "key": 18, "techVal": "aga_wasthereforeignretrieval", "techLbl": "Foreign Retrieval" }
    ])
    const setMyProcContext = (myPrcs) => { if (myPrcs) setMyProc(myPrcs) }
    const setProcCodesContext = (PrcCds) => { if (PrcCds) setProcCodes(PrcCds) }
    const setProcTechsContext = (PrcThs) => { if (PrcThs) setProcTechs(PrcThs) }
    useEffect(() => {
        (async () => {
            let resp = await apiRequest('GET_CODE', 'POST', { "key": process.env.REACT_APP_API_KEY })
            if (resp.status == 'success') {
                setProcCodes(resp.data.map(procCde => {
                    return ({
                        "aga_procedurecodeid": procCde['aga_procedurecodeid'],
                        "aga_procedurecode": procCde['aga_procedurecode'],
                        "aga_description": procCde['aga_description']
                    })
                }))
            } else console.log('API Failed');
        })()
    }, []);

    return (
        <AppContextStore.Provider value={{
            procCodes: procCodes,
            myProc: myProc,
            procTechs: procTechs,
            procTechsFull: procTechsFull,
            setProcCodesContext,
            setProcTechsContext,
            setMyProcContext,
            setProcTechsFull
        }}>
            {props.children}
        </AppContextStore.Provider>
    )
}

export default AppContextProvider;