import '../../App.css';
import './ProcedureSummary.css';
import { useEffect } from 'react';
import moment from "moment/moment";
import { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiRequest } from "../../Utils/request";
import { Button, Table, Row, Col } from 'react-bootstrap';
import AppContextStore from "../../context/AppContextStore";
import { ReactComponent as Print } from '../../Assets/Svgs/Print.svg';
import { ReactComponent as BackArrow } from '../../Assets/Svgs/BackArrow.svg';
import { PageLoader } from "../../Components/Templates/PageLoader/PageLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as ArrowRght } from '../../Assets/Svgs/ArrowRight.svg';

export const ProcedureSummary = () => {
    const { myProc, procCodes, procTechsFull, setMyProcContext } = useContext(AppContextStore);
    const {user} = useAuth0();
    const [dateTo, setDateTo] = useState('');
    const [actFltr, setActFltr] = useState(false);
    const [dateFrom, setDateFrom] = useState('');
    const [dateMinTo, setDateMinTo] = useState('');
    const [procHist, setProcHist] = useState([]);
    const [procSumm, setProcSumm] = useState([]);
    const [myProcLoc, setMyProcLoc] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isCodeMapped, setCodeMapped] = useState(false);
    const mapProcCodes = (myPrcData) => {
        myPrcData.map((proc, ind) => {
            procCodes.map(code => {
                if (code.aga_procedurecodeid == proc._aga_procedurecode_value)
                    myPrcData[ind]._aga_procedurecode_desc = code.aga_description
            })
        })
        setMyProcContext(myPrcData);
        setCodeMapped(true);
    }

    useEffect(() => {
        if(!myProc.length) {
            setLoading(true);
            apiRequest('GET_PROC', 'POST', { "key": process.env.REACT_APP_API_KEY, "contactid": user.sub.split('|').pop() })
                .then(resp => {
                    setLoading(false);
                    if (resp.status == 'success') setMyProcLoc(resp.data)
                    else console.log('API Failed');
                }).catch(err => {
                    setLoading(false);
                    console.log('err: ', err);
                });
        }
    }, []);

    useEffect(() => {
        if (!isCodeMapped && procCodes.length > 0 && myProcLoc.length > 0) mapProcCodes(myProcLoc)
    }, [myProcLoc]);

    const startDateChange = date => {
        setDateFrom(date);
        if (dateTo < date) setDateTo('');
        setDateMinTo(date);
    }

    useEffect(() => {
        if (dateFrom && dateTo) {
            setProcHist(myProc.filter(proc => {
                let procDate = new Date(proc['aga_proceduredate@OData.Community.Display.V1.FormattedValue'])
                return procDate >= dateFrom && procDate <= dateTo;
            }))
            if(actFltr == false) setActFltr(true);
        } else if(actFltr) setActFltr(false);
    }, [dateFrom, dateTo, myProc])

    useEffect(() => {
        if(procHist.length) {
            let summary = [];
            procCodes.reduce((acc, curr) => [...acc, curr.aga_description], []).map(code => {
                let procByDesc = procHist.filter(proc => proc['_aga_procedurecode_desc'] == code);
                summary.push({
                    code: code,
                    total: procByDesc.length,
                    techs: procByDesc.length ? getTechSummary(procByDesc) : {}
                });
            })
            summary.sort(function (one, two) {
                if (one.code < two.code) return -1;
                if (one.code > two.code) return 1;
                return 0;
            });
            setProcSumm(summary);
        } else setProcSumm([]);
    }, [procHist])

    const getTechSummary = procChunk => {
        let techSumm = {};
        procTechsFull.map(procTech => { techSumm[procTech['techLbl']] = procChunk.filter(proc => proc[procTech.techVal]).length })
        return techSumm;
    }

    return (<>
        { isLoading && <PageLoader />}
        <div id='procedure-summary'>
            <div className="d-flex flex-wrap justify-content-between mb-4">
                <div className="d-flex flex-wrap align-items-center">
                    <h4 className='d-flex'>
                        <BackArrow className="actionIcon big mr-2 bck-btn" role="button" title="Go Back" onClick={() => window.history.back()} />
                        Procedure log Summary
                    </h4>
                    <div className="d-flex align-items-center mx-2">
                        <DatePicker id="summ-from" selected={dateFrom} maxDate={new Date()} onChange={date => startDateChange(date)} />
                        <ArrowRght className='actionIcon big mx-2'/>
                        <DatePicker id="summ-to" selected={dateTo} minDate={dateMinTo} maxDate={new Date()} onChange={date => setDateTo(date)} />
                    </div>
                </div>
                <Button variant="primary" className="btn-sm ml-auto mr-2 no-print" onClick={() => window.print()} style={{ fontSize: '1rem'}} disabled={!actFltr}>
                    <Print fill="black" className="actionIcon mr-2" /> Print
                </Button>
            </div>
            <h3>{user.name}</h3>
            {actFltr ? (<>
                {/* <p className='text-danger'>
                    *Please contact us at <a href='mailto:education@gastro.org'>education@gastro.org</a> if you need a record of your log. The feature
                    to download a spreadsheet is being implemented.
                </p> */}
                {procSumm.length > 0 ? (<>
                    <div className="procedureSection">
                        <div className="sectionHeader py-3 font-weight-bold">Procedure Log Summary ({moment(dateFrom).format('MM/DD/YYYY') + (dateFrom != dateTo ? (' - ' + moment(dateTo).format('MM/DD/YYYY')) : '')})</div>
                        {/* New Optimized Design. */}
                        {/* <Table bordered className="tbl-summary mb-3">
                            <thead>
                                <tr>
                                    <th width="30%">Total - Procedure Name</th>
                                    <th width="70%">Total - Techniques used</th>
                                </tr>
                            </thead>
                            <tbody>
                                {procSumm.map((procSum, ind) => (
                                    <tr key={ind}>
                                        <td>{procSum.total} - {procSum.code}</td>
                                        <td>
                                            {Object.keys(procSum.techs).length > 0 ? (
                                                <Row className='mx-0'>
                                                    {Object.keys(procSum.techs).map((tech, ind) => (
                                                        <Col key={ind} md={6}>{procSum.techs[tech]} - {tech}</Col>
                                                    ))}
                                                </Row>
                                            ) : (<> - </>)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table> */}
                        {/* Old page Design */}
                        <Row className='mx-0'>
                            {procSumm.map((procSum, ind) => (
                                <Col key={ind} md={3} className='px-1'>
                                    <Table bordered className="mb-3">
                                        <thead>
                                            <tr>
                                                <th>Total</th>
                                                <th>Procedure Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{procSum.total}</td>
                                                <td>
                                                    <span className='font-weight-bold'>{procSum.code}</span>
                                                    <div className='mt-3'>
                                                        {Object.keys(procSum.techs).map((tech, ind) => (
                                                            <div key={ind}>{procSum.techs[tech]} - {tech}</div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="procedureSection">
                        <div className="sectionHeader py-3 font-weight-bold">Procedure Log History ({moment(dateFrom).format('MM/DD/YYYY') + (dateFrom != dateTo ? (' - ' + moment(dateTo).format('MM/DD/YYYY')) : '')})</div>
                        <Table striped hover bordered>
                            <thead style={{color:'#0c5e8d'}}>
                                <tr>
                                    <th>Procedure Code</th>
                                    <th>Inst</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {procHist.map(proc => (
                                    <tr key={proc.aga_procedurelogid}>
                                        <td>{proc._aga_procedurecode_desc}</td>
                                        <td>{proc.aga_institutionhospital}</td>
                                        <td>{proc["aga_proceduredate@OData.Community.Display.V1.FormattedValue"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>) : (<div>
                    <h6 className='my-5 text-danger text-center'> No Logs found for the selected date range. </h6>
                </div>)}
            </>) : (<div>
                <h6 className='my-5 text-primary text-center'> Select a date range to get a Summary of your logs. </h6>
            </div>)}
        </div>
    </>);
}