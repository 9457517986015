import '../../../App.css';
import { ReactComponent as Home } from '../../../Assets/Svgs/Home.svg';
import { ReactComponent as BackArrow } from '../../../Assets/Svgs/BackArrow.svg';
import Container from 'react-bootstrap/Container';
import { useAuth0 } from '@auth0/auth0-react';

export const Header = () => {
    const {isAuthenticated, logout} = useAuth0();
    return (
        <header>
            <div className="headerBar">
                <Container className="headerBarActions">
                    {isAuthenticated && <>
                        <a role="button" onClick={() => logout({ returnTo: window.location.origin + '/login'})} className="d-flex align-items-center pr-4">
                            <BackArrow fill="white" className="headerActionIcon mr-1" />Logout
                        </a>
                        <a href={process.env.REACT_APP_MY_AGA_LINK} className="d-flex align-items-center">
                            <Home fill="white" className="headerActionIcon mr-1" />My AGA
                        </a>
                    </>}
                </Container>
            </div>
            <Container className="headerLogo">
                <img alt="AGA Logo" className="siteLogo" src="/logo_full.png" />
            </Container>
        </header>
    );
}