import React from 'react'

const AppContextStore = React.createContext({
    procCodes: [],
    procTechs: [],
    setProcCodesContext: (codes) => {},
    setProcTechsContext: (techs) => {},
    setMyProcContext: (logs) => {}
})

export default AppContextStore;