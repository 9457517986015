import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout } from './Components/Templates/Layout/Layout';
import { Login } from './Pages/Login/Login'
import { MyProcedures } from './Pages/MyProcedures/MyProcedures';
import { AddProcedure } from './Pages/AddProcedure/AddProcedure';
import { ProcedureSummary } from './Pages/ProcedureSummary/ProcedureSummary';
import { NotFound } from './Pages/NotFound/NotFound';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated } = useAuth0()
  let location = useLocation()
  return (
    <Routes>
      <Route element={<Layout />} >
        <Route exact path='page-not-found' element={<NotFound />} />
        {isAuthenticated ? (
          <>
            <Route path='my-procedures' element={<MyProcedures pageTitle='My Procedures' />} />
            <Route path='procedure-summary' element={<ProcedureSummary pageTitle='Procedure Summary' />} />
            <Route path='add-procedure' element={<AddProcedure pageTitle='New Procedure' />} />
            <Route path='edit-procedure/:id' element={<AddProcedure pageTitle='Edit Procedure' />} />
            <Route exact path='/' element={<Navigate to={location?.state?.pathname || 'my-procedures'} />} />
            <Route exact path='*' element={<Navigate to="page-not-found" state={location} />} />
          </>
        ) : (
          <>
            <Route path='/' element={<Login />} />
            <Route exact path='*' element={<Navigate to="/" state={location} />} />)
          </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
