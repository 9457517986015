let HEADERS = {
    'Content-Type': 'application/json',
    'Api-Key': process.env.REACT_APP_API_KEY
};
const cngSuccRspPatt = /^[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}$/;
const enPoints = {
    GET_PROC: 'https://prod-95.westus.logic.azure.com:443/workflows/2afb236ff9f44872874e975a5b2db369/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=D2fTKx0UgXbHxW_6nJplJoMbo4f_fP3SfXEoiK20N50',
    GET_CODE: 'https://prod-139.westus.logic.azure.com:443/workflows/1065d6e54eeb431e88e2e26d9da5bbfa/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rTsXvxBlT1Z2Qvr4zkMf7jO7xXbjVYc9JNFHqnlWExY',
    CNG_PROC: 'https://prod-146.westus.logic.azure.com:443/workflows/6e260ce0ee2c432ca6e3fd63262c4c07/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xKb93PUDOTJ-gIM420NesXY82hgIXujcGeod45jhKPQ'
}
export const apiRequest = async (urlCode, method, body = undefined, token = false) => {
    let resolveResp = {}, options = {};
    options.method = method;
    try {
        if (method !== 'GET' && method !== 'HEAD' && typeof body !== 'undefined') options.body = JSON.stringify(body);
        else if (method === 'POST' || method === 'PUT') options.body = body;
        if (token) {
            const additionalHeader = {};
            if (token) additionalHeader.Authorization = `bearer ${token}`;
            options.headers = { ...HEADERS, ...additionalHeader };
        } else options.headers = HEADERS;
        const response = await fetch(enPoints[urlCode], options);
        if (response.status === 200) {
            if(urlCode == 'CNG_PROC') {
                let strRsp;
                try {
                    strRsp = await response.text();
                    resolveResp.data = JSON.parse(strRsp);
                    resolveResp.status = 'failed';
                } catch (e) {
                    resolveResp.status = cngSuccRspPatt.test(strRsp) ? 'success' : 'failed';
                }
            } else {
                resolveResp.status = 'success';
                resolveResp.data = await response.json();
            }
        } else {
            if(response.status === 400) {
                resolveResp.status = 'Invalid Data';
                let data = await response.json();
                resolveResp.msg = Object.values(data).join('\n');
            } else if (response.status === 403) {
                resolveResp.status = 'Session Expired!';
                resolveResp.msg = 'Login again to Continue.';
            } else if (response.status > 400 && response.status < 500) {
                resolveResp.status = 'Invalid Request!';
                resolveResp.msg = 'Something went wrong. Please try again later.';
            } else if (response.status >= 500) {
                resolveResp.status = 'Server Error!';
                resolveResp.msg = 'Server Error Occurred. Please try again later.';
            }
        }
    } catch (error) { console.error(error); }
    return resolveResp;
};