import React, { useEffect } from 'react';
import './Login.css';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from '../../Components/Templates/PageLoader/PageLoader';

export const Login = () => {
    let location = useLocation();
    let returnURL = location?.state?.pathname;
    returnURL = (!returnURL || returnURL == "/login") ? '/' : returnURL;
    let loginOpts = {
        appState: { returnTo: returnURL }
    }
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    useEffect(() => {
        if (!isAuthenticated && !isLoading) loginWithRedirect(loginOpts)
    }, [isAuthenticated, isLoading])
    return (<><PageLoader /></>);

}